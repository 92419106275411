<template>
  <div class="payrecord">
    <top-bar title="账户信息" hasBack></top-bar>
    <div>
      <van-cell title="个人信息"
                class="cell1"
                title-style="font-size:23px;font-weight:bold;"
                is-link size="large"
                value="查看详情"
                @click="$router.push({ name: 'personalDetails'})">
        <van-icon slot="right-icon" name="youjiantou" class-prefix="my-icon"></van-icon>
      </van-cell>

      <div style="color: #7d7e80"></div>
      <van-cell title="户号信息" class="cell2" is-link size="large"
                @click="checkGuestNo() && $router.push({ name: 'guestNoInfo',query:{bindId: wxUserInfo[0].bindId}})">
        <van-icon slot="icon" name="myinfo" class-prefix="my-icon"></van-icon>
        <van-icon slot="right-icon" name="youjiantou" class-prefix="my-icon"></van-icon>
      </van-cell>
      <!--      <van-cell title="户号同步" class="cell2" is-link size="large" @click="$router.push({ name: 'guestNoSync'})">-->
      <!--        <van-icon slot="icon" name="tongbu" class-prefix="my-icon"></van-icon>-->
      <!--        <van-icon slot="right-icon" name="youjiantou" class-prefix="my-icon"></van-icon>-->
      <!--      </van-cell>-->
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import {GoodsMixin} from '@/mixins/goodsMixin'
import ajax from '@/api'

export default {
  name: 'AccountInfo',
  mixins: [GoodsMixin],
  inject: ['reload'],
  components: {TopBar},
  data() {
    return {
      wxUserInfo: [],
      data: {},
      bindId: this.$route.query.bindId
    }
  },
  watch: {},
  created() {
    this.getWxUserInfo()
  },
  methods: {
    // 检查户号
    checkGuestNo() {
      if (this.wxUserInfo.length === 0) {
        this.$dialog.alert({message: '请先绑定户号'}).then(() => {
          this.$router.push({name: 'UserBind'})
        })
        return false
      }
      return true
    },
    // 获取微信用户信息
    async getWxUserInfo() {
      await ajax.getAllAccount({
        userId: this.wxUserId,
      }).then((res) => {
        if (res.data instanceof Array) {
          this.wxUserInfo = res.data || []
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "style";
</style>
